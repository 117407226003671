.select-dropdown {
  .pf-c-select__menu {
    max-height: 200px;
    overflow: auto;
  }
}

.pf-v5-c-select__menu {
  max-height: 18rem;
  overflow-y: auto;
}
